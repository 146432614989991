import React, { useState } from "react"
import { graphql, navigate } from "gatsby"
import ReactPaginate from 'react-paginate';
import { Fade } from "react-awesome-reveal";

import { labels, localeUrls } from '../langs/langs'

import Layout from "../components/layout"
import Seo from "../components/seo"
import PartnersSlider from "../components/PartnersSlider";
import IntroBanner from "../components/IntroBanner";
import { NewsCard, NewsModal } from "../components/NewsCon";
import { lSVG, rSVG } from "../components/ArrowLink";

const StoreDetailTemplate = ({ location, data, pageContext: { localeID, totalPages, pageNumber } }) => {

    const stores = data.allNews.nodes;

    const emptyMsg = <div><h3 className="empty">{labels[localeID]["WEARESORRY"]}</h3><p className="empty">{labels[localeID]["NOTHINGNOW"]}</p></div>;

    const [modalOpened, setModalOpened] = useState(false);
    const [modalData, setModalData] = useState({});

    const handlePageChange = (page) => {
        if (!!page.selected) {
            navigate(localeUrls[localeID]["NEWS"] + "/" + (page.selected + 1));
            return;
        }
        navigate(localeUrls[localeID]["NEWS"]);
    }

    let year = 9999;

    return (
        <Layout location={location} localeID={localeID}>
            <Seo title={labels[localeID]["NEWS"]} description={labels[localeID]["NEWS"]} />
            <IntroBanner localeID={localeID} titleKey="NEWS"/>
            <NewsModal item={modalData} opened={modalOpened} close={()=>setModalOpened(false)}/>
            <div className=""></div>
            <div className="hPadSec cardCon newsCon">
                <div className="content">
                    {!!stores.length ? stores.map((item, index)=>{
                        if (item.year < year) {
                            year = item.year
                            return <React.Fragment key={index}>
                                <Fade><h3 className="b">{item.year}</h3></Fade>
                                <NewsCard item={item} localeID={localeID} onMore={()=>{setModalData(item);setModalOpened(true);}}/>
                            </React.Fragment>
                        }
                        return <NewsCard item={item} key={index} localeID={localeID} onMore={()=>{setModalData(item);setModalOpened(true);}}/>
                    }) : emptyMsg}
                </div>
                {!!(totalPages - 1) && <div className="content pages">
                    <ReactPaginate
                        previousLabel={<>{lSVG('#202020')} {labels[localeID]["PREV"]}</>}
                        nextLabel={<>{labels[localeID]["NEXT"]} {rSVG('#202020')}</>}
                        pageCount={totalPages}
                        onPageChange={handlePageChange}
                        containerClassName={'tablePagination'}
                        forcePage={pageNumber-1}
                    />
                </div>}
            </div>
            <PartnersSlider className="gray" localeID={localeID}/>
        </Layout>
    )
}

export default StoreDetailTemplate

export const pageQuery = graphql`
    query NewsArchiveQuery(
        $category: String!
        $localeID: String!
        $offset: Int!
        $postsPerPage: Int!
    ) {
        allNews: allWpPost(
            filter: {categories: {nodes: {elemMatch: {slug: {eq: $category}}}}, locale: {locale: {eq: $localeID}}}
            sort: { fields: [date], order: DESC }
            limit: $postsPerPage
            skip: $offset
        ) {
            nodes {
                ...PostSliderData
                year: date(formatString: "YYYY")
                content
            }
        }
    }
`
