import React, { useState } from "react";
import moment from 'moment'
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import { Fade } from "react-awesome-reveal";
import { useParallax } from 'react-scroll-parallax';

import AniLink from "gatsby-plugin-transition-link/AniLink"
import { transitionLink } from '../const/attr'

import { labels, localeUrls, days } from '../langs/langs'

import { rSVG } from "./ArrowLink"

import calendarI from '../images/calendar.svg'
import crossI from '../images/cross.svg'

import p1img from '../images/parallax/parallax-5.png'
import p2img from '../images/parallax/parallax-6.png'
import p3img from '../images/parallax/parallax-7.png'
import p4img from '../images/parallax/parallax-8.png'

const NewsCon = ({ data, localeID }) => {

    const news = data.nodes;

    const emptyMsg = <div><h3 className="empty">{labels[localeID]["WEARESORRY"]}</h3><p className="empty">{labels[localeID]["NOTHINGNOW"]}</p></div>;

    const [modalOpened, setModalOpened] = useState(false);
    const [modalData, setModalData] = useState({});

    const parallax1 = useParallax({ speed: 10 });
    const parallax2 = useParallax({ speed: 20 });
    const parallax3 = useParallax({ speed: 30 });
    const parallax4 = useParallax({ speed: 10 });

    return (
        <>
            <NewsModal item={modalData} opened={modalOpened} close={()=>setModalOpened(false)}/>
            <div id="newsCon" className="hPadSec concrete s">
                <img ref={parallax1.ref} style={{top:-100,right:-50}} className="parallax" src={p1img}/>
                <img ref={parallax3.ref} style={{bottom:150,left:100}} className="parallax" src={p3img}/>
                <img ref={parallax2.ref} style={{bottom:50,left:-30}} className="parallax" src={p2img}/>
                <img ref={parallax4.ref} style={{bottom:50,right:20,zIndex:0}} className="parallax" src={p4img}/>
                <div className="content">
                    <h2 className="b">{labels[localeID]["NEWS"]}</h2>
                    <div className="squareGrid">
                        
                        {!!news.length ? news.map((item, index)=>{
                                return <NewsCard item={item} key={index} localeID={localeID} onMore={()=>{setModalData(item);setModalOpened(true);}}/>
                            }) : emptyMsg}
                    </div>
                    <Fade>
                        <AniLink {...transitionLink} to={localeUrls[localeID]["NEWS"]} className="aArow big" style={{color:"#ED6E47"}} color="#ED6E47">{labels[localeID]["SHOWALLNEWS"]} {rSVG("#ED6E47")}</AniLink>
                    </Fade>
                </div>
            </div>
        </>
    )
}

export const NewsCard = ({ item, localeID, onMore }) => <div className="newsCard">
    <div className="shadow">
        <div>
            <GatsbyImage image={item?.featuredImage?.node?.localFile?.childrenImageSharp[0]?.gatsbyImageData} alt={item?.featuredImage?.node?.altText ?? "Ilustracia"} />
        </div>
        <div className="infoBox center">
            <div>
                <p className="center"><img src={calendarI}/>
                    <span className="oh">{item.date} | {days(localeID)[moment(item.dateBasicFormat).day()]}</span>
                </p>
                <h3>{item.title}</h3>
                <a onClick={onMore} className="aArow" style={{color:"#ED6E47"}} color="#ED6E47">{labels[localeID]["MORE"]} {rSVG("#ED6E47")}</a>
            </div>
        </div>
    </div>
</div>

export const NewsModal = ({ item, opened, close }) => {
    if (Object.keys(item).length === 0) return <></>;
    return (<div id="modalGallery" className={"center " + (opened ? 'open' : '')}>
        <div className="shadow">
            <p className="center"><img src={calendarI}/>
                <span className="oh">{item.date}</span>
                <span className="fillFlex"/>
                <button onClick={close} className="center"><img src={crossI}/></button>
            </p>
            <div>
                <GatsbyImage image={item?.featuredImage?.node?.localFile?.childrenImageSharp[0]?.gatsbyImageData} alt={item?.featuredImage?.node?.altText ?? "Ilustracia"} />
            </div>
            <div className="infoBox">
                <h3>{item.title}</h3>
                {parse((!!item.content ? item.content : ''))}
            </div>
        </div>
    </div>)
}


export default NewsCon;
